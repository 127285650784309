import { css } from "@emotion/react";
import { Table, useMantineTheme } from "@mantine/core"
import * as React from "react"
import { useState } from "react";
import Layout from "../components/layout";

// static data
const teachers = [
  { id: 0, firstName: "John", middleName: "Walter", lastName: "Jones", email: "johnwjones@school.edu" },
  { id: 1, firstName: "John", middleName: "Walter", lastName: "Jones", email: "johnwjones@school.edu" },
  { id: 2, firstName: "John", middleName: "Walter", lastName: "Jones", email: "johnwjones@school.edu" },
  { id: 3, firstName: "John", middleName: "Walter", lastName: "Jones", email: "johnwjones@school.edu" }
];

export default function TeachersPage(props) {
  // static mapped school data
  const teacherRows = teachers.map((teacher) => (
    <tr key={teacher.id}>
      <td>{teacher.firstName}</td>
      <td>{teacher.middleName}</td>
      <td>{teacher.lastName}</td>
      <td>{teacher.email}</td>
    </tr>
  ));

  // Table thread style
  const threadStyle = css({
    display: "table-header-group"
  });

  return (
    <Layout>
      <Table striped highlightOnHover withColumnBorders>
        <thread css={threadStyle}>
          <tr>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Email</th>
          </tr>
        </thread>
        <tbody>{teacherRows}</tbody>
      </Table>
    </Layout>
  );
}